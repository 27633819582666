<template>
  <div class="container-page big">
    <div class="unfriendly-country-statuses">
      <h2>Статусы недружественных стран</h2>
      <div class="unfriendly-country-statuses__actions">
        <div class="unfriendly-country-statuses__load-btn">
          <ButtonStock
            :disabled="importInProgress"
            :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
            @click="openImportModal"
          ></ButtonStock>
        </div>

        <div class="unfriendly-country-statuses__search">
          <div class="w-100">
            <p class="unfriendly-country-statuses__label">Страна</p>
            <SelectMultiComponentCheckbox
              placeholder="Все"
              v-model="countryIds"
              :options="getCountriesSelect"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
            />
          </div>
        </div>
      </div>

      <p class="unfriendly-country-statuses__describe">
        Объемы экспорта и импорта, попавшие под санкционные ограничения недружественных стран (млн $)
      </p>

      <div class="table-container-new">
        <table class="stockTable country-statuses-table">
          <thead>
            <tr>
              <th class="country-statuses-table__td"><p>№</p></th>
              <th class="country-statuses-table__td"><p>Страна</p></th>
              <th class="country-statuses-table__td"><p>Количество ограничений</p></th>
              <th class="country-statuses-table__td"><p>Стоимость экспорта под ограничениями</p></th>
              <th class="country-statuses-table__td"><p>Стоимость импорта под ограничениями</p></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in unfriendlyCountries" :key="item.id">
              <td class="country-statuses-table__td">{{ index + 1 }}</td>
              <td class="country-statuses-table__td">{{ item.country.name }}</td>
              <td class="country-statuses-table__td">{{ formatNumber(item.numberOfRestrictions, 0) }}</td>
              <td class="country-statuses-table__td">{{ formatNumber(item.exportValueUnderRestrictions, 1) }}</td>
              <td class="country-statuses-table__td">{{ formatNumber(item.importValueUnderRestrictions, 1) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <section>
      <ModalComponent v-model="isOpenImport" title="Импорт файла" @close="closeImportModal">
        <div class="load-template-btn">
          <label @click="fetchTemplate">Скачать шаблон</label>
        </div>
        <ImportModal @doimport="loadImportDocument" @close="closeImportModal"></ImportModal>
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ImportModal from '@/components/modals/ImportModal';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/index';
  import {
    IMPORT_ANALYTICS_ACTIONS_NAME,
    IMPORT_ANALYTICS_GETTERS_NAME,
  } from '../store/importAnalytics/directoryNames';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'UnfriendlyCountryStatuses',
    mixins: [text],
    components: {
      ButtonStock,
      ImportModal,
      ModalComponent,
      SelectMultiComponentCheckbox,
    },
    data: () => ({
      isOpenImport: false,
      importInProgress: false,
      countryIds: [],
      unfriendlyCountries: [],
    }),
    computed: {
      ...mapGetters({
        getUnfriendlyCountries: IMPORT_ANALYTICS_GETTERS_NAME.getUnfriendlyCountries,
        getCountriesSelect: DIRECTIVES_GETTERS_NAME.getCountriesSelect,
      }),
    },
    watch: {
      countryIds: {
        handler(values) {
          const ids = values.map((id) => Number(id));

          this.unfriendlyCountries =
            ids.length > 0
              ? this.getUnfriendlyCountries.filter(({ countryId }) => ids.includes(countryId))
              : this.getUnfriendlyCountries;
        },
      },
    },
    mounted() {
      this.fetchUnfriendlyCountries();
      this.fetchCountries();
    },
    methods: {
      ...mapActions({
        getUnfriendlyCountriesSearch: IMPORT_ANALYTICS_ACTIONS_NAME.getUnfriendlyCountriesSearch,
        loadUnfriendlyCountriesReport: IMPORT_ANALYTICS_ACTIONS_NAME.loadUnfriendlyCountriesReport,
        countriesGet: DIRECTIVES_ACTIONS_NAME.countriesGet,
      }),
      openImportModal() {
        this.isOpenImport = true;
      },
      closeImportModal() {
        this.isOpenImport = false;
      },

      async fetchCountries() {
        await this.countriesGet();
      },
      async fetchUnfriendlyCountries() {
        const res = await this.getUnfriendlyCountriesSearch();
        if (res.success) {
          this.unfriendlyCountries = this.getUnfriendlyCountries;
        }
      },
      async fetchTemplate() {
        this.importInProgress = true;
        this.closeImportModal();

        API.getUnfriendlyCountriesTemplate()
          .catch(() => {
            Constants.alert.fire('Скачивание файла', 'Не удалось скачать шаблон', 'error');
          })
          .finally(() => {
            this.importInProgress = false;
          });
      },

      async loadImportDocument(file) {
        this.importInProgress = true;
        this.closeImportModal();
        const res = await this.loadUnfriendlyCountriesReport({ file });
        if (res.success) {
          Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
        } else {
          Constants.alert.fire('Импорт', 'Ошибка при импорте данных', 'error');
        }
        this.importInProgress = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/UnfriendlyCountryStatuses.scss';
</style>
